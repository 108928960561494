<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title><span v-if="!isSmall">GreatScots </span>List Editor</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog v-if="isOwner" v-model="deleteDialog" width="300">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="error" class="mr-2" small>
            <v-icon left>fal fa-trash</v-icon>
            Delete
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Confirm Delete</v-card-title>
          <v-card-text>
            <p>Are you sure you want to delete this list? This action cannot be undone.</p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="deleteList()">Delete List</v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="deleteDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn to="/greatscots/edit" exact small>Back to List Edit Home</v-btn>
    </v-toolbar>
    <v-toolbar>
      <v-toolbar-title>
        <v-text-field v-if="editTitleActive" v-model="title" label="List Title" @keyup.enter="saveTitleChange()" hide-details outlined dense class="mt-4">
          <template v-slot:append-outer>
            <v-btn icon @click="saveTitleChange" style="margin-top:-12px">
              <v-icon>fal fa-save</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <template v-else>
          <span style="font-size:1.1em">{{ title }}</span>
          <v-tooltip v-if="isOwner" top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon class="ml-4" @click="editTitleActive = true" style="margin-top:-6px">
                <v-icon small>fal fa-pencil</v-icon>
              </v-btn>
            </template>
            <span>Change the title of this list</span>
          </v-tooltip>
        </template>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <share :id="listId"></share>
    </v-toolbar>
    <v-expansion-panels v-model="activeGroup">
      <v-expansion-panel v-for="(group, index) in groups" :key="'group-' + index">
        <v-expansion-panel-header>{{ group.group }} ({{ group.people.length }} members)</v-expansion-panel-header>
        <v-expansion-panel-content>
          <edit-group
            :title="group.group"
            :people="group.people"
            @save="({ title }) => { group.group = title; save() }"
            @add="(id) => { group.people.push(id); save() }"
            @remove="(id) => { remove(id, group.people) }"
            @deleteGroup="deleteGroup(group, index)"></edit-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-actions>
      <v-btn text @click="addGroup" style="margin-top:1em;">
        <v-icon left>fal fa-plus</v-icon>
        Add Group
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {
    EditGroup: () => import('@/components/greatscots/editor/EditGroup'),
    Share: () => import('@/components/greatscots/editor/Share')
  },
  setup (props, { root }) {
    const service = root.$feathers.service('directory/list')
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const activeGroup = ref(null)
    const listId = computed(() => root.$route.params.id)
    const title = ref('')
    const editTitleActive = ref(false)

    const share = ref([])
    const userRole = computed(() => {
      const arr = share.value.filter(({ user: shareUser }) => JSON.stringify(shareUser) === JSON.stringify(user.value.directoryId))
      if (arr.length > 0) return arr[0].role
      return false
    })
    const isOwner = computed(() => userRole.value === 'owner')
    const isAdmin = computed(() => userRole.value === 'admin' || userRole.value === 'owner')

    const groups = ref([])
    const addGroup = () => {
      groups.value.push({ group: 'New Group', permissions: [], people: [] })
      save()
    }

    const isSmall = computed(() => ['xs', 'sm'].includes(root.$vuetify.breakpoint.name))

    watch(listId, async (id) => {
      try {
        const data = await service.get(id)
        // console.log(data)
        if (data.type !== 'list') {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'The selected list is not editable' })
          root.$router.push('/greatscots/edit')
          return
        }
        title.value = data.title
        share.value = data.share
        // Make sure the user has permission to edit the list (i.e. they are in the share list and are not a viewer)
        const rec = share.value.filter(({ user: shareUser }) => JSON.stringify(shareUser) === JSON.stringify(user.value.directoryId))
        if (rec.length === 0 || rec[0].role === 'viewer') {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'You do not have permission to edit this list' })
          root.$router.push('/greatscots/edit')
          return
        }
        groups.value = data.groups
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Either the list you attempted to access does not exist or you do not have permission to access it.' })
        root.$router.push('/greatscots/edit')
      }
    })

    async function saveTitleChange () {
      if (title.value !== '' && title.value != null) {
        // Check to make sure there is not a ilst owned by the same person with the same name
        try {
          await service.patch(listId.value, { title: title.value })
          editTitleActive.value = false
        } catch (e) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error updating title: ' + e })
        }
      }
    }

    async function save () {
      try {
        await service.patch(listId.value, { groups: groups.value || [] })
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error updating list: ' + e })
      }
    }

    function remove (id, group) {
      for (let i = 0; i < group.length; i++) {
        if (group[i] === id) {
          group.splice(i, 1)
          save()
        }
      }
    }

    async function deleteGroup (group, index) {
      if (confirm('Are you sure you want to delete the group labeled "' + group.group + '"?')) {
        groups.value.splice(index, 1)
        try {
          await service.patch(listId.value, { groups: groups.value })
        } catch (e) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error deleting group: ' + e })
        }
      }
    }

    const deleteDialog = ref(false)
    async function deleteList () {
      try {
        await service.remove(listId.value)
        root.$router.push('/greatscots/edit')
        root.$store.dispatch('main/snackbar', { color: 'success', text: 'List deleted successfully' })
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error deleting list: ' + e })
      }
    }

    return {
      user,
      activeGroup,
      listId,
      title,
      editTitleActive,
      share,
      isOwner,
      isAdmin,
      groups,
      addGroup,
      isSmall,
      saveTitleChange,
      save,
      remove,
      deleteGroup,
      deleteDialog,
      deleteList
    }
  }
}
</script>
